import React, { useEffect, useState } from 'react';
import AdminNavBar from '../components/adminNavBar';
import config from '../config';
import '../styles/admin.css'
import { handleAxios } from '../components/modules/AxiosModule';
import GenerateLoadingModal from '../components/GenerateLoadingModal';
import Alerts from '../Alerts';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const AdminHistory = () => {
  const navigate = useNavigate()
  const { currentUser, authloading } = useAuth()
  const apiURL = config.ADMIN_HISTORY;
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAscending, setIsAscending] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  useEffect(() => {
    if (!authloading && !currentUser.isAdmin) {
      Alerts.wrongAccess();
      navigate('/');
    }
  // eslint-disable-next-line
  }, [currentUser, authloading]);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);

      try {
        const storedToken = JSON.parse(localStorage.getItem('token'));
        
        if (storedToken && storedToken.token) {
          const response = await handleAxios("get", apiURL);

          if (Array.isArray(response.data)) {
            setImages(response.data);
            // console.log('Received data:', response.data);
          } else {
            console.error('Data is not an array', response.data);
          }
        } else {
          console.error('No token found');
        }
      } catch (error) {
        console.error('Fetching users failed:', error);
      }
    };

    fetchUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (images.length === 0) {
      setIsLoading(false);
    }
  }, [images]);

  useEffect(() => {
    if (imagesLoaded === images.length && images.length > 0) {
      setIsLoading(false);
    }
  }, [imagesLoaded, images]);

  const toggleSortOrder = () => {
    setIsLoading(true);
    setIsAscending(!isAscending);
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const handleImageLoad = () => {
    setImagesLoaded(prevCount => prevCount + 1);
  };

  const handlePageChange = (pageNumber) => {
    setIsLoading(true);
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'auto' });
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };
  

  const sortedImages = isAscending ? images : images.slice().reverse();
  const indexOfLastImage = currentPage * itemsPerPage;
  const indexOfFirstImage = indexOfLastImage - itemsPerPage;
  const currentImages = sortedImages.slice(indexOfFirstImage, indexOfLastImage);

  const totalPages = Math.ceil(images.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageRange = 2; // 현재 페이지 앞뒤로 보여줄 페이지 수

    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPages = [1, 2];
      const endPages = [totalPages - 1, totalPages];

      // Add start pages
      for (let i = 0; i < startPages.length; i++) {
        pageNumbers.push(startPages[i]);
      }

      // Add middle pages with "..." separators
      if (currentPage <= 5) {
        for (let i = 3; i <= 7; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
      } else {
        if (currentPage > pageRange + 2) {
          pageNumbers.push("...");
        }
        for (let i = Math.max(currentPage - pageRange, 3); i <= Math.min(currentPage + pageRange, totalPages - 2); i++) {
          pageNumbers.push(i);
        }
        if (currentPage < totalPages - pageRange - 1) {
          pageNumbers.push("...");
        }
      }

      // Add end pages
      for (let i = 0; i < endPages.length; i++) {
        pageNumbers.push(endPages[i]);
      }
    }

    return pageNumbers;
  };

  return (
    <div className='adminMembers'>
      <AdminNavBar />
      {isLoading && (
        <GenerateLoadingModal />
      )}

      <div className='entire-history'>
        <div className='sort-buttons'>
          <button onClick={toggleSortOrder}
            style={{ width: 200, height: 40, margin: 10, borderRadius: 15, fontSize: 17,}}>
            {isAscending ? '내림차순으로 보기' : '오름차순으로 보기'}
          </button>
        </div>
        <div className='images-container'>
          {currentImages.map((image, index) => (
            <img src={image} alt={`${index}`} onLoad={handleImageLoad} />
          ))}
        </div>
        <div className='pagination'>
          {renderPageNumbers().map((number, index) => (
            <button
              key={index}
              onClick={() => typeof number === 'number' && handlePageChange(number)}
              className={number === currentPage ? 'active' : ''}
              disabled={number === "..."}
            >
              {number}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminHistory;
