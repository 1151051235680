import React, { useEffect, useRef, useState } from 'react'
import '../styles/ConceptGenerater.css'
import KonvaCanvas from '../components/KonvaCanvas'
import ControlPanel from '../components/ControlPanel'
import { useAuth } from '../context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom'
import config from '../config'
import { useCredit } from '../context/CreditContext'
import { handleAxios } from '../components/modules/AxiosModule';
import { stripBase64Prefix, makeNewImage, cropImage } from '../components/modules/functions'
import Alerts from '../Alerts'
import GenerateLoadingModal from '../components/GenerateLoadingModal'

const UniteGenerater = () => {
  const { currentUser, loginCheck, authloading } = useAuth();
  const { checkCredits, fetchCredits } = useCredit();
  const navigate = useNavigate();
  const location = useLocation();
  const [isPanelActive, setIsPanelActive] = useState(false);
  const [images, setImages] = useState([]);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [selectedId, setSelectedId] = useState(null); 
  const stageRef = useRef(null);
  const transformerRef = useRef(null);
  const canvasWidth = 512;
  const canvasHeight = 512;
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedBg, setSelectedBg] = useState([]);
  const [analyzedData, setAnalyzedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  // 비로그인시 메인으로
  useEffect(() => {
    loginCheck('/')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, authloading])

  useEffect(() => {
    if (location.state?.upFile) {
      const img = new Image();
      img.src = location.state.upFile;  // Base64 문자열을 직접 src로 사용
      img.onload = () => {
        const newImageId = `image-${Date.now()}`
        const newImage = makeNewImage({
          canvasWidth: canvasWidth,
          canvasHeight: canvasHeight,
          image: img,
          id: newImageId
        });
        
        setImages([newImage]);
        setSelectedId(newImage.id);
      };
      img.onerror = () => {
        console.error("Failed to load image");
      };
    }
  }, [location.state?.upFile]);
  

  // 이미지 업로드
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true)
      setLoadingText("이미지를 업로드 중입니다.")
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new window.Image();
        img.src = e.target.result;
        img.onload = () => {
          const newImageId = `image-${Date.now()}`
          const newImage = makeNewImage({
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            image: img,
            id: newImageId
          });

          setImages(prev => [...prev, newImage]);
          setSelectedId(newImage.id);
        };
      };
      reader.readAsDataURL(file);
      event.target.value = '';
      setIsLoading(false)
    }
  };

  // 캔버스 클릭시 업로드 창 띄움
  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  // 배경 제거
  const handleBackgroundRemoveClick = async () => {
    fetchCredits();
    if (!selectedId) {
      Alerts.needImgSelect()
      return;
    }
    const selectedImage = images.find(img => img.id === selectedId); 
    const stage = stageRef.current;
    if (!stage) {
      Alerts.noStage()
      return;
    }
    setUndoStack([...undoStack, images]);
    setRedoStack([]);
    setLoadingText("로보브러시가 배경을 제거하고 있습니다.")
    setIsLoading(true);
    try {
      const imageNode = stage.findOne(`#${selectedId}`);
      const imageDataUrl = imageNode.toDataURL({ pixelRatio: 3 });
      const apiRefURL = config.REMOVE_BG_URL;
      const strippedImageData = stripBase64Prefix(imageDataUrl);
      const requestData = {
        image: strippedImageData
      }
      const response = await handleAxios("post", apiRefURL, requestData);

      const img = new window.Image();
      img.crossOrigin = "anonymous";
      img.src = `data:image/png;base64,${response.data}`;
      img.onload = () => {
        cropImage(img, (croppedBlob) => {
          const objectUrl = URL.createObjectURL(croppedBlob);
          const croppedImage = new window.Image();
          croppedImage.src = objectUrl;
          croppedImage.onload = () => {
            const newImageId = `img-${new Date().getTime()}`;
            const newImage = makeNewImage({
              selectedImage: selectedImage,
              croppedImage: croppedImage,
              image: img,
              id: newImageId
            });
            // 기존 이미지를 제외한 나머지 이미지들을 가져와서 새 이미지를 추가
            const filteredImages = images.filter(img => img.id !== selectedId);
            setImages([...filteredImages, newImage]);
            setSelectedId(newImage.id);
          };
        });
      };
    } catch (error) {
      Alerts.bgRemoveError(error)
    } finally {
      setIsLoading(false);
      setCurrentStep(2)
    }
  };
  
  // 분석하기 버튼
  const handleAnalyze = () => {
    clearSelection();
    setTimeout(() => {
      const stage = stageRef.current.getStage();
      const imageDataUrl = stage.toDataURL({
        mimeType: 'image/png',
        quality: 1,
        pixelRatio: 3
      });
      setSelectedProduct(imageDataUrl); // 캔버스의 상품 상태 저장
      // callAnalyzeAPI(imageDataUrl)
    }, 10);
  };

  // Konva 선택 해제
  const clearSelection = () => {
    if (transformerRef.current) {
      transformerRef.current.nodes([]);
      const layer = transformerRef.current.getLayer();
      if (layer) {
        layer.draw();
      }
    }
  };

  // const updateSelectedProduct = (imageDataUrl) => {
  //   setSelectedProduct(imageDataUrl);
  //   // console.log(selectedProduct)
  // };

  // const handleAnalyze = () => {
  //   clearSelection();
  //   setTimeout(() => {
  //     const stage = stageRef.current.getStage();
  //     const imageDataUrl = stage.toDataURL({
  //       mimeType: 'image/png',
  //       quality: 1,
  //       pixelRatio: 3
  //     });
  //     setSelectedProduct(imageDataUrl);
  //     callAnalyzeAPI(imageDataUrl)
  //   }, 10);
  //   // callAnalyzeAPI()
  // };

  const callAnalyzeAPI = async () => {
    if (selectedProduct && selectedBg.length > 0) {
      if (window.confirm('이대로 분석하시겠습니까?')) {
        setLoadingText("로보브러시가 이미지를 분석하고 있습니다.")
        setIsLoading(true);
  
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = async () => {
          const maxWidth = 512;
          const maxHeight = 512;
          const scale = Math.min(maxWidth / img.width, maxHeight / img.height);
          const scaledWidth = img.width * scale;
          const scaledHeight = img.height * scale;
  
          canvas.width = maxWidth;
          canvas.height = maxHeight;
          // 회색 배경 채우기
          ctx.fillStyle = '#808080';
          ctx.fillRect(0, 0, maxWidth, maxHeight);
          // 이미지 중앙에 배치
          const offsetX = (maxWidth - scaledWidth) / 2;
          const offsetY = (maxHeight - scaledHeight) / 2;
          ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);
  
          const imageDataUrl = canvas.toDataURL('image/png');
  
          // API 호출
          const analAPI = config.ANALYZE_URL;
          try {
            const requestData = {
              creator_sub: currentUser.sub,
              my_product: imageDataUrl,
              background: [selectedBg],
            };
            // console.log(requestData)
            const response = await handleAxios("post", analAPI, requestData);

            // API 응답 처리
            const responseData = {
              my_product_img: imageDataUrl,
              my_product: response.data.my_product,
              background: response.data.background,
              pov: response.data.pov,
            };
            // console.log('responseData:', responseData);
            setAnalyzedData(responseData)
            setCurrentStep(3)
            setIsLoading(false);
          } catch (error) {
            Alerts.imgAnalError(error)
            setIsLoading(false);
          }
        };
        img.onerror = (error) => {
          Alerts.imgLoadFailed(error)
          setIsLoading(false);
        };
        img.src = selectedProduct;
      } else {
        setSelectedProduct(null);
      }
    } else if (selectedBg.length === 0) {
      Alerts.needSelect(1)
    }
  };

  // 캔버스 상태가 저장되면 분석 함수 실행
  useEffect(() => {
    if (selectedProduct && selectedBg.length > 0) {
      callAnalyzeAPI();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);


  // 생성 요청
  const handleGenerate = async () => {
    const creditsAvailable = checkCredits(2);
    if (!creditsAvailable) return;
    setLoadingText("로보브러시가 이미지를 만들고 있어요.")
    setIsLoading(true);
    const promptSentence = `This scene features the product '${analyzedData.my_product}', viewed from ${analyzedData.pov}. The background features a ${analyzedData.background}.`
    const requestData = {
      creator_sub: currentUser.sub,
      my_product_img: analyzedData.my_product_img,
      my_product: analyzedData.my_product,
      background: analyzedData.background,
      pov: analyzedData.pov,
      prompts: [promptSentence]
    };
    // // console.log('Sending request data:', requestData);
    const apiRefURL = config.CONCEPT_GEN_URL;
    try {
      const response = await handleAxios("post", apiRefURL, requestData);
  
      if (Array.isArray(response.data) && response.data.length === 0) {
        Alerts.NSFW();
        return;
      } else if (response.data) {
        await fetchCredits();
        navigate('/conceptresult', { state: { imageData: response.data, requestData: requestData } });
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          Alerts.noCredits(error);
          navigate('/pricing')
        } else {
          Alerts.generateFailed(error)
        }
      } else {
        Alerts.generateFailed(error)
      }
    } finally {
      setIsLoading(false);
    }
  };

  // 모바일 UI에서 스텝 단계
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;
  const nextStep = () => {
    if (currentStep < totalSteps) setCurrentStep(currentStep + 1);
  };
  const prevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };


  return (
    <div className='UniteGenerater'>
      
      {isLoading && <GenerateLoadingModal text={loadingText} />}

      <div className='con-column'>
        <div className='con-row'>

          <div className='canvas-area'>
            <KonvaCanvas
              setIsLoading={setIsLoading}
              handleBackgroundRemoveClick={handleBackgroundRemoveClick}
              setImages={setImages}
              images={images}
              redoStack={redoStack}
              setRedoStack={setRedoStack}
              undoStack={undoStack}
              setUndoStack={setUndoStack}
              transformerRef={transformerRef}
              stageRef={stageRef}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              canvasWidth={canvasWidth}
              canvasHeight={canvasHeight}
              setSelectedProduct={setSelectedProduct}
              // updateSelectedProduct={updateSelectedProduct}
            />
          </div>

          <div className='combine-area'>

            <div className='step-buttons'>
              {currentStep > 1 && 
                <button onClick={prevStep} className="left">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              }
              {currentStep < 3 &&
                <button onClick={nextStep} className="right">
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              }
            </div>

            <div className={`combine-inner ${currentStep === 1 ? 'active' : ''}`}>
              <h2>1단계. 편집할 이미지를 넣은 후 배경제거 버튼을 눌러주세요.</h2>
              <div className='combine-content'>
                {images.length === 0 ? (
                  <>
                    <input
                      id='concept-gen-upload'
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                    />
                    <button onClick={openFilePicker} className='uploadBtn' id='concept-gen-uploadBtn'>업로드 <br />이미지</button>
                  </>) : (
                    <div className='myproduct_preview'>
                      <img src={images[images.length - 1].image.src} alt="Uploaded" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                    </div>
                  )}
                <button
                  className={`${images.length > 0 ? 'act' : ''}`}
                  id='concept-gen-removeBG'
                  onClick={handleBackgroundRemoveClick}>배경 제거하기</button>
              </div>
            </div>

            <div className={`combine-inner ${currentStep === 2 ? 'active' : ''}`}>
              <h2>2단계. 배경에 사용하고 싶은 예시 이미지를 넣어주세요.</h2>
              <div className='combine-content'>
                {selectedBg.length === 0 &&
                  <div className='bgvoid'
                    id='concept-gen-activeSearch'
                    onClick={() => {
                      setIsPanelActive(true)
                      inputRef.current?.focus()
                    }}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </div>
                }
                {selectedBg.length >= 1 && 
                  (<div style={{position: 'relative'}}>
                    <img src={selectedBg} alt="배경 레퍼런스 이미지"></img>
                    <div className='deleteBG' onClick={()=>setSelectedBg([])}>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>)}
                <button
                  className={`${images.length > 0 && selectedBg.length > 0 ? 'act' : 'dis'}`}
                  id='concept-analyze'
                  disabled={!(images.length > 0 && selectedBg.length > 0)}
                  onClick={handleAnalyze}>분석하기</button>
              </div>
            </div>

            <div className={`combine-inner ${currentStep === 3 ? 'active' : ''}`}>
              <h2>3단계. {analyzedData && <span style={{ color: 'gold' }}>분석 완료!</span>} 컨셉이미지를 생성해볼까요?</h2>
              <div className='combine-content'>
                <button
                  className={`${analyzedData ? 'act' : 'dis'}`}
                  id='concept-generate'
                  disabled={!analyzedData}
                  onClick={handleGenerate}>컨셉 이미지 생성하기</button>
              </div>
            </div>
            
          </div>

        </div>

      </div>
      <ControlPanel
        selected={selectedBg}
        setImageData={setSelectedBg}
        setIsLoading={setIsLoading}
        isPanelActive={isPanelActive}
        setIsPanelActive={setIsPanelActive}
        inputRef={inputRef}
      />
    </div>
  )
}

export default UniteGenerater