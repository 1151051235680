import React, { useRef, useContext } from 'react';
import { useTag } from '../context/TagContext';
import { useImages } from '../context/ImageContext';
import { useNavigate } from 'react-router-dom';

import { InputWand } from './Inputs';
import Alerts from '../Alerts';
import config from '../config';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import { PromptContext } from '../context/PromptContext';
import { handleAxios } from '../components/modules/AxiosModule';
import { stripBase64Prefix, makeNewImage } from './modules/functions';

import { WandSparkles } from 'lucide-react';

const Wand = ({ children }) => {
  return (
    <div className='flex flex-col h-60 mt-16 mx-auto
                    max-lg:w-full'>
      <div className='w-[800px] text-center bg-[#24303F] rounded-lg mx-auto drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]
                      max-lg:w-full
                    '>
        {children}
      </div>
    </div>
  );
};

const WandTags = ({ children }) => {
  return (
    <div className='flex space-x-3 h-20 px-4 mb-4 border-b-2 border-b-gray-950
                    max-sm:space-x-1'>
      {children}
    </div>
  );
};

const WandTag = ({ children, value }) => {
  const { currentTag, setCurrentTag } = useTag();
  const isActive = value === currentTag;

  return (
    <button
      onClick={() => setCurrentTag(value)}
      className={`h-[50px] my-auto rounded-full
                  max-sm:h-[36px]
        ${isActive
          ? 'bg-gradient-to-r from-blue-500 to-purple-600'
          : 'bg-transparent'}
      `}
    >
      <span className='px-6 font-bold text-2xl text-white
                      max-sm:text-sm max-sm:px-2
                    '>
        {children}
      </span>
    </button>
  );
};

const WandInputForm = ({ children, btnId, setIsLoading }) => {
  const { currentTag } = useTag();

  return (
    <div className='flex px-4 pb-4'>
      {currentTag === 'GEN' && <WandTextInput btnId={btnId} setIsLoading={setIsLoading}/>}
      {currentTag === 'COM' && <>
                  <WandImageInput setIsLoading={setIsLoading}>
                    <WandButton setIsLoading={setIsLoading}>
                      <WandSparkles size={32}/>
                    </WandButton>
                  </WandImageInput>
                </>}
      {children}
    </div>
  );
};

const WandTextInput = ( {btnId, setIsLoading} ) => {
  const { fetchCredits, checkCredits } = useCredit();
  const { currentUser, loginCheck } = useAuth();
  const navigate = useNavigate();
  const apiURL = config.PROMPT_GEN_APIURL;
  const { prompt, setPrompt } = useContext(PromptContext);

  const handleSubmit = async (prompt) => {
    const isLoggedIn = loginCheck({ showAlert: false, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) {
      setIsLoading(true);
      try {
        const requestData = {
          prompt: prompt,
        }
        const response = await handleAxios("post", apiURL, requestData);
  
        if (response.data) {
          navigate('/mycanvas-result', { state: { prompt, imageData: response.data, guest: true } });
        } else if (response.data && response.data.message) {
          alert(response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          Alerts.invalidRequest(error)
        } else {
          Alerts.generateFailed(error)
        }
      } finally {
        setIsLoading(false);
        setPrompt('')
      }
      return;
    }

    // 로그인 상태일 때 크레딧 검증 후 생성 요청
    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;
    setIsLoading(true);
    try {
      const requestData = {
        user_sub: currentUser.sub,
        prompt: prompt,
      }
      const response = await handleAxios("post", apiURL, requestData);

      if (response.data) {
        await fetchCredits();
        navigate('/mycanvas-result', { state: { prompt, imageData: response.data } });
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          Alerts.noCredits()
          navigate('/pricing');
        } else {
          Alerts.invalidRequest(error)
        }
      } else {
        Alerts.generateFailed(error)
      }
    } finally {
      setIsLoading(false);
      setPrompt('')
    }
  }
  
  return (
    <div className='flex w-full'>
      {/* <input
        className='h-[99%] w-[98%] bg-transparent text-white text-2xl my-0'
        placeholder={placeHolder}
      /> */}
      <InputWand 
        handleSubmit={handleSubmit} 
        prompt={prompt} 
        setPrompt={(value) => {
          setPrompt(value);
        }}
        buttonText={<span className='m-auto font-bold text-white'>
                      <WandSparkles size={32}/>
                    </span>
                  }
        btnId={btnId}
      />
    </div>
  );
};

const WandImageInput = ({ children, setIsLoading }) => {
  const fileInputRef = useRef(null);
  const canvasWidth = 512;
  const canvasHeight = 512;
  const { images, setImages } = useImages([]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true)
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new window.Image();
        img.src = e.target.result;
        img.onload = () => {
          const newImage = makeNewImage({
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            image: img
          })
          // const newImageId = `${Date.now()}`
          setImages(prev => [...prev, newImage]);
        };
      };
      reader.readAsDataURL(file);
      event.target.value = '';

      setIsLoading(false)
    }
  };
  const openFilePicker = () => {
    if (images.length < 2) {
    fileInputRef.current.click();
    } else if (images.length >= 2) {
      Alerts.selectLimit(2);
    }
  };
  const deselectImage = (image) => {
    setImages(images.filter(selected => selected !== image));
  };

  return (
    <div className='flex w-full justify-between'>
      <div className='flex'>
      <input
        id='concept-gen-upload'
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <button onClick={openFilePicker} className='w-32 h-32 bg-gray-900 mr-4 text-4xl text-white rounded-lg
                                                  max-sm:w-16 max-sm:h-16'>+</button>
      {images.length > 0 && (
        <div className='flex myproduct_preview gap-4'>
          {images.map((img, index) => (
            <div key={index} className='relative block my-auto'>
              <img className='object-contain max-w-32 max-h-32 max-sm:w-16 max-sm:h-16'
                key={index}
                src={img.image.src}
                alt={`Uploaded ${index}`}
              />
              <button className="absolute text-4 top-0 right-0 px-2 font-bold bg-gray-200" onClick={() => deselectImage(img)}>X</button>
            </div>
          ))}
          
        </div>
      )}
      </div>
      { children }
    </div>
  );
};

const WandButton = ({ children, setIsLoading }) => {
  const navigate = useNavigate()

  const apiRefURL = config.REF_GEN_APIURL;

  const { currentTag } = useTag();
  const { currentUser, loginCheck } = useAuth();
  const { images , setImages} = useImages([]);
  const { fetchCredits, checkCredits } = useCredit();

  const imagesClear = () => {
    setImages([])
  }

  const handleReferenceSubmit =  async () => {
    const isLoggedIn = loginCheck({ showAlert: true, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) return
    
    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;
    setIsLoading(true);
    try {
      const base64Images = await Promise.all(
        images.map(async (image) => {
          const base64 = stripBase64Prefix(image.image.src);
          // image.image.src.replace('data:', '').replace(/^.+,/, '');
          return base64;
        })
      );
      const jsonData = JSON.stringify({ data: base64Images, user_sub: currentUser.sub, imgids: images.map(image => image.id) });

      const response = await handleAxios("post", apiRefURL, jsonData);

      if (response.status === 200) {
        await fetchCredits();
        navigate('/mycanvas-result', { state: { data: response.data, base64Images } });
        // navigate('/refresult', { state: { data: response.data, imgids: images.map(image => image.id) } });
      } else if (response.status === 400 && response.data.detail === "Not enough credits") {
        Alerts.noCredits()
        navigate('/pricing');
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
      
    } catch (error) {
      console.error('이미지 생성 중 에러가 발생했습니다. 다시 시도해 주세요.', error);
      Alerts.generateFailed(error)
    } finally {
      imagesClear();
      setIsLoading(false);
    }
  };

  return (
    <>
      <button className='flex justify-content w-[50px] h-[50px] my-auto bg-gradient-to-r from-blue-500 to-purple-600 rounded-full'
              onClick={currentTag === 'COM' ? handleReferenceSubmit
                        : ''}
      >
        <span className='m-auto font-bold text-white'>
          {children}
        </span>
      </button>
    </>
  );
};

export { Wand, WandTags, WandTag, WandInputForm, WandButton };
