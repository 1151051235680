import React, { useState } from 'react';
import Footer from './Footer';
import { TagProvider } from '../context/TagContext';
import { ImgProvider } from '../context/ImageContext';
import { Wand, WandTags, WandTag, WandInputForm } from './Wand';
import LandingGallery from './LandingGallery';
import GenerateLoadingModal from './GenerateLoadingModal';

const Landing = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading && <GenerateLoadingModal text="로보브러시가 이미지를 만들고 있어요." />}
      <div className='flex flex-col min-h-screen'>
        <div className='flex flex-col px-[15%] sm:pt-12 pt-6 max-lg:px-4'>
          <TagProvider>
            <Wand>
              <WandTags>
                <WandTag value="GEN">텍스트로 이미지 생성</WandTag>
                <WandTag value="COM">이미지와 이미지 조합</WandTag>
              </WandTags>
              <ImgProvider>
                <WandInputForm
                  btnId='prompt-gen'
                  setIsLoading={setIsLoading}
                />
              </ImgProvider>
            </Wand>
          </TagProvider>
        </div>
        <LandingGallery setIsLoading={setIsLoading} />
      </div>
      <Footer />
    </>
  );
};

export default Landing;
