import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GradientOutlineButton, OutlineButton } from '../components/Buttons';
import { FaCoins } from 'react-icons/fa';
import { copyImageToClipboard, downloadImage, useCanvasImageLoader } from './modules/canvasFuntions';
import { useAuth } from '../context/AuthContext';
import SignupModal from './SignupModal';
import GenerateLoadingModal from './GenerateLoadingModal';

const ResultTextGuest = ({ imageData, isLoading, canvasWidth, canvasHeight }) => {
  const { currentUser, googleLogin, guestWater } = useAuth();
  const navigate = useNavigate();
  const backgroundImage = imageData.b64image;
  const canvasRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackTarget, setFeedbackTarget] = useState(null);

  // 캔버스에 로드
  useCanvasImageLoader(canvasRef, backgroundImage, canvasHeight, canvasWidth)

  // 재생성 요청
  const handleLogin = async () => {
    setShowModal(true);
  }

  // 회원 정보 변경(로그인) 의존성 실행. url 변경해서 리디렉션 (컴포넌트 변경 유발)
  useEffect(() => {
    if (imageData.url && currentUser && imageData.uuid) {
      const updatedUrl = imageData.url.replace('temp-container', 'robobrush-container');
      const targetUrl = guestWater === false ? updatedUrl : imageData.url;
      navigate('/mycanvas-result', { state: { image: { url: targetUrl } } });
    }
    // eslint-disable-next-line
  }, [currentUser, guestWater]);

  const showFeedback = (target, message) => {
    setFeedbackTarget(target);
    setFeedbackMessage(message);
    setTimeout(() => {
      setFeedbackMessage(null);
      setFeedbackTarget(null);
    }, 2000); // 2초 뒤 메시지 제거
  };


  return (
    <div className="flex flex-col items-center justify-start px-4 mt-20">
      {isLoading && <GenerateLoadingModal />}
      <div className="relative w-full max-w-[660px] h-auto aspect-square bg-gray-200 mb-4">
        <canvas className="w-full h-full object-contain"
          ref={canvasRef} 
          width={canvasWidth} 
          height={canvasHeight} 
        ></canvas>
      </div>
      {!isLoading && (
        <div className="grid grid-cols-2 gap-4 sm:w-[auto] w-[90%]">
          <GradientOutlineButton text="로그인 후 AI 편집" handleClick={handleLogin} />
          <GradientOutlineButton text="로그인 후 다시 생성" leftIcon={<FaCoins className="text-[16px] sm:text-[24px] align-middle" />} handleClick={handleLogin} />
          <div className="relative">
            <OutlineButton
              text="복사"
              handleClick={() => {
                copyImageToClipboard(canvasRef);
                showFeedback('copy', '복사 완료! 로그인 하면 원본사진을 복사 할 수 있어요.');
              }}
            />
            {feedbackTarget === 'copy' && (
              <p className="text-red-500 text-[7px] sm:text-xs text-center absolute -bottom-5 left-0 right-0 transform animate-shake whitespace-nowrap">
                {feedbackMessage}
              </p>
            )}
          </div>
          <div className="relative">
            <OutlineButton
              text="다운로드"
              handleClick={() => {
                downloadImage(canvasRef);
                showFeedback('download', '다운로드 완료! 로그인 하면 원본사진을 받아 볼 수 있어요.');
              }}
            />
            {feedbackTarget === 'download' && (
              <p className="text-red-500 text-[7px] sm:text-xs text-center absolute -bottom-5 left-0 right-0 transform animate-shake whitespace-nowrap">
                {feedbackMessage}
              </p>
            )}
          </div>
        </div>
      )}

      <SignupModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        message="서비스를 이용하시려면"
        message1="로그인/회원가입이 필요합니다."
        message2="로그인/회원가입 하시겠습니까?"
        onConfirm={() => {
          googleLogin();
          setShowModal(false);
        }}
      />
    </div>
  );
}

export default ResultTextGuest;