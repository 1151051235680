import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function AdminNavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="fixed top-16 z-[1300] w-full h-[74px] bg-[rgba(17,24,32,.8)] flex justify-between items-center px-4 box-border select-none">
      {/* 메뉴 토글 버튼 (모바일 전용) */}
      <button className="md:hidden text-white text-3xl cursor-pointer"
        onClick={toggleMobileMenu}
      >☰</button>

      <div className='flex justify-start items-center w-full'>
        {/* 데스크탑 및 모바일 메뉴 */}
        <div className={`flex flex-col md:flex-row md:items-center space-x-0 md:space-x-10 text-white w-full justify-center ${isMobileMenuOpen ? 'fixed top-16 left-0 w-full bg-[rgba(17,24,32,0.95)] z-40 p-5' : 'hidden md:flex'}`}>
          <Link to="/admin/memberlist">
            <div className={`text-lg md:text-xl my-2 md:my-0 transition-transform duration-200 ${isActive('/admin/memberlist') ? 'font-semibold underline scale-105 text-shadow-lg' : ''}`}>
              회원정보
            </div>
          </Link>
          <Link to="/admin/history">
            <div className={`text-lg md:text-xl my-2 md:my-0 transition-transform duration-200 ${isActive('/admin/history') ? 'font-semibold underline scale-105 text-shadow-lg' : ''}`}>
              히스토리
            </div>
          </Link>
          <Link to="/admin/prompt">
            <div className={`text-lg md:text-xl my-2 md:my-0 transition-transform duration-200 ${isActive('/admin/prompt') ? 'font-semibold underline scale-105 text-shadow-lg' : ''}`}>
              생성하기
            </div>
          </Link>
          <Link to="/admin/reference">
            <div className={`text-lg md:text-xl my-2 md:my-0 transition-transform duration-200 ${isActive('/admin/reference') ? 'font-semibold underline scale-105 text-shadow-lg' : ''}`}>
              조합하기
            </div>
          </Link>
          <Link to="/admin/scrolls">
            <div className={`text-lg md:text-xl my-2 md:my-0 transition-transform duration-200 ${isActive('/admin/scrolls') ? 'font-semibold underline scale-105 text-shadow-lg' : ''}`}>
              스크롤
            </div>
          </Link>
          <Link to="/admin/prompthistory">
            <div className={`text-lg md:text-xl my-2 md:my-0 transition-transform duration-200 ${isActive('/admin/prompthistory') ? 'font-semibold underline scale-105 text-shadow-lg' : ''}`}>
              텍스트생성
            </div>
          </Link>
          <Link to="/admin/sample">
            <div className={`text-lg md:text-xl my-2 md:my-0 transition-transform duration-200 ${isActive('/admin/sample') ? 'font-semibold underline scale-105 text-shadow-lg' : ''}`}>
              랜딩샘플
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AdminNavBar;
