import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { GradientOutlineButton, OutlineButton } from '../components/Buttons';
import { FaCoins } from 'react-icons/fa';
import { handleAxios } from '../components/modules/AxiosModule';
import Alerts from '../Alerts';
import { copyImageToClipboard, downloadImage, useCanvasImageLoader } from './modules/canvasFuntions';
import GenerateLoadingModal from './GenerateLoadingModal';

const ResultText = ({ imageData, text, currentUser, loginCheck, checkCredits, fetchCredits, isLoading, setIsLoading, canvasWidth,
  setCanvasWidth, canvasHeight, setCanvasHeight }) => {

  const navigate = useNavigate();
  const [backgroundImage, setBackgroundImage] = useState(imageData.b64image);
  const prompt = text;
  const canvasRef = useRef(null);
  const apiURL = config.PROMPT_GEN_APIURL;

  // 캔버스에 로드
  useCanvasImageLoader(canvasRef, backgroundImage, canvasHeight, canvasWidth)

  // 재생성 요청
  const handlePromptSubmit = async (prompt) => {
    const isLoggedIn = loginCheck({ showAlert: true, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) return
    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;
    setIsLoading(true);
    try {
      const requestData = {
        user_sub: currentUser.sub,
        prompt: prompt,
      };

      const response = await handleAxios("post", apiURL, requestData);

      if (response.data) {
        await fetchCredits();
        setBackgroundImage(response.data.b64image)
        navigate('/mycanvas-result', { state: { prompt, imageData: { b64image: response.data.b64image } } });
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          Alerts.noCredits(error)
          navigate('/pricing');
        } else {
          Alerts.invalidRequest(error)
        }
      } else {
        Alerts.generateFailed(error)
      }
    } finally {
      setIsLoading(false);
    }
  }

  // 편집 페이지로
  const handleEditStart = () => {
    // 모바일 경고 함수
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      Alerts.onlyDesktop()
    }
    navigate('/mycanvas-edit', {
      state: { image: { b64image: backgroundImage, url: imageData.url } },
    });
  };

  return (
    <div className="flex flex-col items-center justify-start px-4 mt-20">
      {isLoading && <GenerateLoadingModal text="로보브러시가 이미지를 만들고 있어요." />}
      <div className="relative w-full max-w-[660px] h-auto aspect-square bg-gray-200 mb-4">
        <canvas className="w-full h-full object-contain"
          ref={canvasRef} 
          width={canvasWidth} 
          height={canvasHeight} 
        ></canvas>
      </div>
      {!isLoading && (
        <div className="grid grid-cols-2 gap-4 sm:w-[auto] w-[90%]">
          <GradientOutlineButton text="AI 캔버스 편집하기" handleClick={handleEditStart} />
          <GradientOutlineButton text="다시 생성하기" leftIcon={<FaCoins className="text-[16px] sm:text-[24px] align-middle" />} handleClick={() => handlePromptSubmit(prompt)} />
          <OutlineButton text="복사" handleClick={() => copyImageToClipboard(canvasRef)} />
          <OutlineButton text="다운로드" handleClick={() => downloadImage(canvasRef)} />
        </div>
      )}
    </div>
  );
}

export default ResultText;