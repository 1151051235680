import { GoogleLogin } from '@react-oauth/google'
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import config from '../config';

export const GoogleLoginBtn = () => {
  const { currentUser, setMyToken, setGuestWater } = useAuth();
  const clientId = config.GOOGLE_CLIENT_ID;
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);

  // 저 앱 브라우저의 경우 안 나옴
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIos = /iPhone|iPad|iPod/i.test(userAgent);
    const inAppBrowser = /Instagram|FBAN|FBAV|FB_IAB|FBIOS|FB4A|FBSV|Twitter|Line|KAKAOTALK|NAVER|Naver|Firefox|FxiOS/i.test(userAgent);
    if (isIos && inAppBrowser) {
      setIsInAppBrowser(true);
    }
  }, []);

  if (isInAppBrowser) { return null; }

	return (
		<div className='GoogleSDKContainer'>
      {!currentUser &&
        <GoogleLogin
          clientId={clientId}
          onSuccess={(response) => {
            const decode_token = jwtDecode(response.credential)
            const loginRequestData = (user) => {
              const imgUuid = sessionStorage.getItem('imgUuid'); // 세션스토리지에서 imgUuid 확인
            
              // 기본 로그인 데이터 생성
              const data = {
                sub: user.sub,
                email: user.email,
                name: user.name,
                picture: user.picture,
              };
            
              // imgUuid가 있을 경우 uuid 필드 추가
              if (imgUuid) {
                data.uuid = imgUuid;
              }
            
              return data;
            };
            const data = loginRequestData(decode_token)
            const endpoint = config.LOGIN_ENDPOINT;
            axios.post(endpoint, data,
              {
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8'
                }
              }
            )
            .then(response => {
              setMyToken(data, response)
              setGuestWater(response.data.showWatered)
              sessionStorage.removeItem('imgUuid');
            })
            .catch(error => {
              console.error('Error sending user info to the backend:', error);
            })
          }}
          onError={(response) => {
            console.error("Login Failed", response);
          }}
          width={"350px"}
          shape='circle'
          useOneTap
        />
      }
		</div>
	)
}

export default GoogleLoginBtn