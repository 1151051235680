import React, { useEffect } from 'react'
import '../styles/ConceptLanding.css'
import ConceptLandingInner from '../components/ConceptLandingInner';

const ConceptGenLanding = () => {

  useEffect(() => {
    sessionStorage.removeItem('imgUuid');
  }, []);

  return (
    <div className='ConceptLanding'>
      <ConceptLandingInner />
    </div>
  )
}

export default ConceptGenLanding