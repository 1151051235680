import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GradientOutlineButton, OutlineButton } from '../components/Buttons';
import { handleAxios } from '../components/modules/AxiosModule';
import config from '../config';
import { FaCoins } from 'react-icons/fa';
import Alerts from '../Alerts';
import GenerateLoadingModal from './GenerateLoadingModal';

const ResultHistory = ({ image, checkCredits, loginCheck, fetchCredits, isLoading, setIsLoading }) => {
  const [loadingText, setLoadingText] = useState('')
  const navigate = useNavigate();
  const ReGenApiURL = config.HISTORY_REGEN_APIURL;

  // 재생성 요청 for 이미지 리스트 클릭으로 들어온 경우
  const handleHistoryRegenerate = async () => {
    const isLoggedIn = loginCheck({ showAlert: true, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) return
    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;

    setLoadingText("로보브러시가 이미지를 만들고 있어요.")
    setIsLoading(true);
    
    try {
      const requestData = {
        image_url: image.url,
      }
      const response = await handleAxios("post", ReGenApiURL, requestData);
      
      if (response.data) {
        await fetchCredits();
        navigate('/mycanvas-result', { state: { image: response.data } });
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          Alerts.noCredits(error)
          navigate('/pricing');
        } else {
          Alerts.loadingFailed(error)
        }
      } else {
        Alerts.loadingFailed(error)
      }
    } finally {
      setIsLoading(false);
    }
  };

  // URL 복사
  const handleCopy = () => {
    navigator.clipboard.writeText(image.url).then(() => {
      Alerts.copySuccess()
    }).catch((error) => {
      Alerts.copyFailed(error)
    });
  };

  // 다운로드
  const handleDownload = async () => {
    const endPoint = config.HISTORY_URL_TO_B64_URL;
    try {
      setLoadingText('이미지를 다운로드 중입니다.')
      setIsLoading(true);

      const requestData = {
        image_url: image.url,
      };
  
      // Base64 데이터를 서버 호출
      const response = await handleAxios("post", endPoint, requestData);
      let base64Data = response.data;
  
      // Base64 데이터 앞에 접두사가 없으면 붙여
      if (!base64Data.startsWith("data:image/")) {
        base64Data = `data:image/png;base64,${base64Data}`;
      }
  
      // Base64 데이터를 Blob으로 변환
      const byteString = atob(base64Data.split(",")[1]); // `data:image/png;base64,...`에서 Base64 부분 추출
      const mimeType = base64Data.match(/:(.*?);/)[1]; // MIME 타입 추출
      const buffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(buffer);
  
      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }
  
      const blob = new Blob([buffer], { type: mimeType });
  
      // Blob URL을 생성하고 다운
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "image.png"; // 파일 이름
      document.body.appendChild(link);
      link.click();
  
      // 메모리 정리
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      Alerts.imgLoadFailed(error);
    } finally {
      setIsLoading(false);
    }
  }; 

  // 편집 시작하기 시 url to b64 for edit
  const handleEditStart = async () => {
    // 사용자 디바이스가 모바일인지 확인
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      Alerts.onlyDesktop()
    }

    const endPoint = config.HISTORY_URL_TO_B64_URL;
    try {
      setLoadingText("AI 캔버스를 로딩중입니다.")
      setIsLoading(true);

      let base64Data;
      if (image.b64image) {
        // image 데이터 안에 b64image가 존재할 경우
        base64Data = image.b64image;
      } else {
        // b64image가 없을 경우 기존 방식으로 처리
        const requestData = {
          image_url: image.url,
        };
        const response = await handleAxios("post", endPoint, requestData);

        base64Data = response.data;
      }

      navigate('/mycanvas-edit', { state: { image: {b64image: base64Data, url: image.url} }});
    } catch (error) {
      Alerts.imgLoadFailed(error)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start px-4 mt-20">
      {isLoading && <GenerateLoadingModal text={loadingText} />}
      <div className="w-full max-w-[660px] h-auto aspect-square bg-gray-200 mb-4"
        onContextMenu={(e) => e.preventDefault()} // 우클릭 방지
      >
        <img src={image.url} alt="Selected" className="w-full h-full object-contain" />
      </div>
      {!isLoading && (
        <div className="grid grid-cols-2 gap-4 sm:w-[auto] w-[90%]">
          <GradientOutlineButton text="AI 캔버스 편집하기" handleClick={handleEditStart} />
          <GradientOutlineButton text="다시 생성하기" leftIcon={<FaCoins className="text-[14px] sm:text-[24px] align-middle" />} handleClick={handleHistoryRegenerate} />
          <OutlineButton text="복사" handleClick={handleCopy} />
          <OutlineButton text="다운로드" handleClick={handleDownload} />
        </div>
      )}
    </div>
  );
};

export default ResultHistory;