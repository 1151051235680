import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ResultHistory from '../components/ResultHistory';
import ResultText from '../components/ResultText';
import ResultCombine from '../components/ResultCombine';
import ResultTextGuest from '../components/ResultTextGuest';

const Result = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { checkCredits, fetchCredits } = useCredit();
  const { currentUser, loginCheck, authloading } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(512);
  const [canvasHeight, setCanvasHeight] = useState(512);

  useEffect(() => {
    // location.state가 null이거나 undefined인 경우 바로 메인으로 리다이렉트
    if (!location.state) {
      navigate('/');
      return;
    }
    const { image, data, prompt, imageData } = location.state;
    if (!image && !data && !prompt && !imageData) {
      navigate('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, navigate, location.state, authloading]);

  if (!location.state) { return null; }

  const { image, data, base64Images, prompt, imageData, guest } = location.state;

  return (
    <>
      {!guest && image && (
        <ResultHistory
          image={image}
          loginCheck={loginCheck}
          fetchCredits={fetchCredits}
          checkCredits={checkCredits}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {!guest && data && (
        <ResultCombine
          data={data}
          base64Images={base64Images}
          currentUser={currentUser}
          loginCheck={loginCheck}
          fetchCredits={fetchCredits}
          checkCredits={checkCredits}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          canvasWidth={canvasWidth}
          setCanvasWidth={setCanvasWidth}
          canvasHeight={canvasHeight}
          setCanvasHeight={setCanvasHeight}
        />
      )}
      {!guest && prompt && imageData && (
        <ResultText
          text={prompt}
          imageData={imageData}
          currentUser={currentUser}
          loginCheck={loginCheck}
          fetchCredits={fetchCredits}
          checkCredits={checkCredits}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          canvasWidth={canvasWidth}
          setCanvasWidth={setCanvasWidth}
          canvasHeight={canvasHeight}
          setCanvasHeight={setCanvasHeight}
        />
      )}
      {guest === true && prompt && imageData && (
        <ResultTextGuest
          text={prompt}
          imageData={imageData}
          currentUser={currentUser}
          loginCheck={loginCheck}
          fetchCredits={fetchCredits}
          checkCredits={checkCredits}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          canvasWidth={canvasWidth}
          setCanvasWidth={setCanvasWidth}
          canvasHeight={canvasHeight}
          setCanvasHeight={setCanvasHeight}
        />
      )}
    </>
  );
};

export default Result;