import { Helmet } from 'react-helmet-async';
import Landing from '../components/Landing'
import { useEffect } from 'react';

const Main = () => {    
    useEffect(() => {
        sessionStorage.removeItem('imgUuid');
      }, []);
    return (
        <>
            <Helmet>
                <title>불가능한 이미지는 없습니다. 로보브러시</title>
            </Helmet>
            <Landing />
        </>
        );
    }

export default Main;