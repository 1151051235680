import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import '../styles/Pricing.css';
import QnA from '../components/QnA';
import Footer from '../components/Footer';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import * as PortOne from "@portone/browser-sdk/v2";
import config from '../config';
import axios from 'axios';

// 플랜 UI
const Plan = ({ title, subtitle, features, price, discount, discountPrice, highlight, onClick, isActive }) => (//isSubscription, isActive }) => (
  <div className='plans' onClick={onClick ? onClick : undefined}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h3>{title}</h3>
      {/* {isActive && <span className="active-badge">현재 이용중</span>} */}
    </div>
    <p>{subtitle}</p>
    {highlight && <h4 style={{color: '#ffc700'}}><FaCheck className="check-icon" style={{color: '#ffc700'}} />{highlight}</h4>}
    {features.map((feature, index) => (
      // 
      <h4 key={index} style={{ display: 'flex'}}> 
        <FaCheck className="check-icon" />
        {feature}
      </h4>
    ))}
    {price && (
      <div className='price'>
        {discount && (
          <div className='discount'>
            <p>{discount}</p>
            <h5>{price}</h5>
          </div>
        )}
        <h4>{discountPrice || price}</h4>
      </div>
    )}
  </div>
);

// eslint-disable-next-line
const Menu = ({ label, active, onClick }) => (
  <div
    className={`menu ${active ? 'active' : ''}`}
    onClick={() => onClick(label)}
  >
    {label}
  </div>
);

// 상품 데이터
const pricingData = {
  'AI 도구': {
    // description: 'AI로 이미지를 생성하거나 합성할 수 있어요.',
    description: '크레딧 구매로 더 많은 AI 편집이 가능해요.',
    plans: [
      {
        title: '무료 플랜',
        features: ['매일 무료 크레딧 7개 제공', '무제한 배경 제거'],
        isActive: true
      },
      {
        title: '100 크레딧 구매',
        subtitle: '1일 최대 10번 구매할 수 있어요!',
        features: ['매일 무료 크레딧 7개 제공', '무제한 배경 제거', '워터마크 제거'],
        price: '10,000원',
        discount: '서비스 출시 기념 50% 할인!',
        discountPrice: '5,000원'
      }
    ]
  },
  // '스튜디오': {
  //   description: '30초만에 나만의 광고 디자인을 완성할 수 있어요.',
  //   plans: [
  //     {
  //       title: '무료 플랜',
  //       features: ['매일 무료 크레딧 10개 제공', '무제한 배경 제거'],
  //       isActive: false
  //     },
  //     {
  //       title: '스튜디오 구독',
  //       highlight: '제품 편집 자동화 서비스',
  //       features: ['AI 도구 무제한 이용', '무제한 배경 제거', '워터마크 제거'],
  //       price: '29,000원',
  //       discount: '서비스 출시 기념 65% 할인!',
  //       discountPrice: '9,900원',
  //       // 구독인 경우
  //       isSubscription: true
  //     }
  //   ]
  // }
};

const Pricing = () => {
  // eslint-disable-next-line
  const [activeMenu, setActiveMenu] = useState('AI 도구');
  const { currentUser, loginCheck } = useAuth();
  const { fetchCredits } = useCredit()

  // 결제 호출
  const handlePayment = (plan) => {
    const isLoggedIn = loginCheck({ showAlert: true, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) return
    // console.log('clicked', plan);
    // eslint-disable-next-line
    const { title, discountPrice, isSubscription } = plan;
    const amount = discountPrice.replace(/,/g, '').replace('원', '');
    const paymentId = `${currentUser.sub}${new Date().getTime()}`;
    const channelKey = 'channel-key-27241b7a-03c7-452d-a8ff-13a86c7c0472';
  
    // 전화번호 조건부 설정
    const isValidPhone = currentUser.phone && /^010\d{8}$/.test(currentUser.phone);
    const phoneNumber = isValidPhone 
      ? currentUser.phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3") 
      : "010-0000-1234";
  
    PortOne.requestPayment({
      storeId: "store-62fd6baa-6930-496f-9dc8-7a9f88c0c25b",
      paymentId: paymentId,
      orderName: title,
      totalAmount: amount,
      currency: "CURRENCY_KRW",
      channelKey: channelKey,
      payMethod: "CARD",
      // 배포시 주소 변경 필수!!! 안 하면 모바일 결제 안됨!!!
      redirectUrl: `https://robobrush.ai/payment-result?paymentId=${paymentId}`,
      // redirectUrl: `https://green-ground-0c992cb00-141.eastasia.3.azurestaticapps.net/payment-result?paymentId=${paymentId}`,
      customer: {
        phoneNumber: phoneNumber,
      },
    }).then((rsp) => {
      if (rsp.code != null) {
        // 실패
        return alert(`결제 실패: ${rsp.message}`)
      } else {
        // 성공
        axios.post(config.PORTONE_CHECK_VAL, {
          paymentId: rsp.paymentId,
        })
        .then(response => {
          const data = response.data;
          if (data.status) {
            alert(`결제 완료. 100 크레딧이 지급됐습니다.`)
            fetchCredits()
          } else {
            alert(`결제 검증 실패: ${data.message}`);
          }
        })
        .catch(error => {
          console.error('결제 검증 요청 오류:', error);
          alert(`결제 검증 요청 오류: ${error.response.data.detail}`)
        });
      }
    }).catch((error) => {
      console.error('결제 요청 오류:', error);
    })
  };  

  return (
    <>
      <div className='min-h-screen'>
        <div className='pricing'>
          {/* <div>
            <h1>나에게 맞는 요금제 찾기</h1>
          </div> */}
          {/* <div className='priceToggler'>
            {Object.keys(pricingData).map((menu) => (
              <Menu
                key={menu}
                label={menu}
                active={activeMenu === menu}
                onClick={setActiveMenu}
              />
            ))}
          </div> */}

          <div className='priceContent'>
            <div className='content'>
              <h2>{pricingData[activeMenu].description}</h2>
              <div className='plans-container'>
                {pricingData[activeMenu].plans.map((plan, index) => (
                  <Plan
                    key={index}
                    {...plan}
                    onClick={plan.price ? () => handlePayment(plan) : null}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <QnA />
      </div>
      <Footer />
    </>
  );
};

export default Pricing;