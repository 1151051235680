import React, { createContext, useState } from 'react';

export const PromptContext = createContext();

export const PromptProvider = ({ children }) => {
  const [prompt, setPrompt] = useState('');
  const [shouldConfirm, setShouldConfirm] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [onConfirm, setOnConfirm] = useState(() => () => {});

  const confirmPrompt = (image, handleSubmit) => {
    setCurrentImage(image);
    setShouldConfirm(true);
    setOnConfirm(() => () => {
      handleSubmit(image.prompt);
      setShouldConfirm(false);
      setCurrentImage(null);
    });
  };

  const cancelPrompt = () => {
    setShouldConfirm(false);
    setCurrentImage(null);
  };

  return (
    <PromptContext.Provider value={{ prompt, setPrompt, confirmPrompt, shouldConfirm }}>
      {children}
      {shouldConfirm && currentImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={cancelPrompt} // 바깥 영역 클릭 시 모달 닫기
        >
          <div
            className="bg-white rounded-lg p-6 w-[550px] text-center shadow-lg"
            onClick={(e) => e.stopPropagation()} // 모달 내부 클릭은 이벤트 전파 차단
          >
            <img
              src={currentImage.url}
              alt="Preview"
              className="w-full object-cover rounded-md mb-3"
            />
            <p className="text-lg font-semibold mb-2">{currentImage.prompt}</p>
            <p className="text-lg text-blue-600 font-bold mb-4">이미지를 생성하시겠습니까?</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={cancelPrompt}
                className="w-24 bg-gray-300 text-gray-700 px-4 py-2 rounded-full hover:bg-gray-400"
              >
                아니오
              </button>
              <button
                onClick={onConfirm}
                className="w-24 px-4 py-2 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 text-white hover:from-purple-600 hover:to-blue-600"
              >
                예
              </button>
            </div>
          </div>
        </div>
      )}
    </PromptContext.Provider>
  );
};