import React from 'react';
import { GradientButton } from './Buttons';

const ConfirmModal = ({ isOpen, setIsOpen, message, message1, message2, message3, onConfirm }) => {
  const Confirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.id === 'overlay') {
      setIsOpen(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div
      id="overlay"
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <h2 className="text-xl font-bold">{message}</h2>
        {message1 && <h2 className="text-lg font-semibold mt-4">{message1}</h2>}
        {message2 && <h2 className="text-lg font-semibold mt-4">{message2}</h2>}
        {message3 && <h3 className="text-md font-normal mt-4 text-gray-600">{message3}</h3>}
        <div className="mt-8 flex justify-center">
          <GradientButton text="가입하기" handleClick={Confirm} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;