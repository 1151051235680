import React, { createContext, useContext, useEffect, useState } from 'react';
import config from '../config';
import { handleAxios } from '../components/modules/AxiosModule';

const GalleryContext = createContext();

export const GalleryProvider = ({ children }) => {
  const [galleryImages, setGalleryImages] = useState([]);

  // 배열을 랜덤
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  // 샘플 이미지 호출
  const fetchSampleImages = async () => {
    try {
      const response = await handleAxios('get', config.GET_SAMPLE_IMAGES);
      if (response && response.data) {
        const newImages = response.data.map((image, index) => ({
          id: `sample_${index + 1}`,
          url: image.url,
          prompt: image.prompt,
        }));
        return newImages; // 결과를 반환
      }
    } catch (error) {
      console.error('Error fetching sample images:', error);
    }
    return []; // 에러가 발생하면 빈 배열 반환
  };

  // 추가 텍스트 생성 결과물에서 호출
  const fetchAdditionalImages = async (neededCount) => {
    try {
      const response = await handleAxios('get', config.GET_GEN_IMAGELIST);
      if (response && response.data) {
        const additionalImages = response.data.slice(0, neededCount).map((image, index) => ({
          id: `textGen_${index + 1}`,
          url: image.url,
          prompt: image.prompt || `추가 이미지 ${index + 1}`,
        }));
        return additionalImages; // 결과를 반환
      }
    } catch (error) {
      console.error('Error fetching additional images:', error);
    }
    return []; // 에러가 발생하면 빈 배열 반환
  };

  // 샘플 이미지를 불러오고, 필요한 경우 추가 이미지를 합치는 함수
  const fetchGalleryImages = async () => {
    const sampleImages = await fetchSampleImages();
    const remainingCount = Math.max(14 - sampleImages.length, 0); // 부족한 개수 계산
    let additionalImages = [];

    if (remainingCount > 0) {
      additionalImages = await fetchAdditionalImages(remainingCount);
    }

    // 샘플과 추가 이미지를 합치고, 랜덤으로 섞기
    const combinedImages = shuffleArray([...sampleImages, ...additionalImages]);
    setGalleryImages(combinedImages);
  };

  useEffect(() => {
    fetchGalleryImages();
    // eslint-disable-next-line
  }, []);

  return (
    <GalleryContext.Provider value={{ galleryImages, setGalleryImages }}>
      {children}
    </GalleryContext.Provider>
  );
};

export const useGallery = () => useContext(GalleryContext);