import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { AuthProvider } from './context/AuthContext'
import Navbar from './components/Navbar';
import NotFound from './pages/NotFound';
import './App.css'

import AdminPrompt from './pages/adminPrompt';
import AdminReference from './pages/adminReference';
import AdminScrolls from './pages/adminScrolls'
import AdminMemberList from './pages/adminMemberList';
import AdminHistory from './pages/adminHistory';
import AdminUserPage from './pages/adminUserPage';
///
import AdminPromptHistory from './pages/adminPromptHistory';
import AdminSample from './pages/adminSample';


import GoogleLoginBtn from './components/GoogleLoginBtn';

import UserHistory from './pages/MyPage';
import UpdateInfo from './pages/updateInfo';

import Main from './pages/Main'

import MyCanvasHome from './pages/MyCanvasHome'
import Result from './pages/Result'
import Edit from './pages/Edit'

import ConceptGenLanding from './pages/ConceptGenLanding';
import ConceptGenerater from './pages/ConceptGenerater';
import ConceptResult from './pages/ConceptResult';

import Pricing from './pages/Pricing';
import PaymentResult from './pages/PaymentResult';
import Hotjar from '@hotjar/browser';
import IphoneWarning from './pages/IphoneWarning';
import { CreditProvider } from './context/CreditContext';
import { GalleryProvider } from './context/GalleryContext';
import { PromptProvider } from './context/PromptContext';

const siteId = 5028251;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <GalleryProvider>
          <PromptProvider>
            <CreditProvider>
              <GoogleLoginBtn />
              <Navbar />
                <Routes>
                  <Route path="/mypage" element={<UserHistory />} />
                  <Route path="/updateinfo" element={<UpdateInfo />} />
                                
                  <Route path="/" element={<Main />} />
                  <Route path="/mycanvas-home" element={<MyCanvasHome />} />
                  <Route path="/mycanvas-result" element={<Result />} />
                  <Route path="/mycanvas-edit" element={<Edit />} />

                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/payment-result" element={<PaymentResult />} />

                  <Route path="/conceptlanding" element={<ConceptGenLanding />} />
                  <Route path="/conceptgenerate" element={<ConceptGenerater />} />
                  <Route path="/conceptresult" element={<ConceptResult />} />

                  <Route path="/admin/prompt" element={<AdminPrompt />} />
                  <Route path="/admin/reference" element={<AdminReference />} />
                  <Route path="/admin/scrolls" element={<AdminScrolls />} />
                  <Route path="/admin/memberlist" element={<AdminMemberList />} />
                  <Route path="/admin/history" element={<AdminHistory />} />
                  <Route path="/admin/userpage" element={<AdminUserPage />} />

                  <Route path="/admin/promptHistory" element={<AdminPromptHistory />} />
                  <Route path="/admin/sample" element={<AdminSample />} />
                  
                  <Route path="/iphonewarning" element={<IphoneWarning />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
            </CreditProvider>
          </PromptProvider>
        </GalleryProvider>
      </AuthProvider>
    </BrowserRouter>
    );
  }

export default App;
